.pagination-component {
    margin-top: 15px;
    text-align: right;
    width: 100%;
    .pagination-component-page-group{
        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .pagination-component-range {
        text-align: left;
        padding-top: 5px;
        font-weight: 400;
        font-size: 13px;
    }
}
