.detail-user-box-avatar {
    width: 90px;
    height: 90px;
    border: 1px dashed #dcdcdc;
    border-radius: 9px;
    overflow: hidden;
}
.detail-user-full-name {
    font-weight: 700;
    font-size: 16px;
    color: rgb(121, 121, 121);
}