.image-card {
    display: inline-block;
    position: relative;

    input[type="checkbox"][id^="cb"] {
        display: none;
    }

    label {
        border: 4px solid #fff;
        display: block;
        position: relative;
        margin: 10px;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    label::before {
        background-color: white;
        color: white;
        content: " ";
        display: block;
        border: 1px solid #0073aa;
        position: absolute;
        top: -5px;
        left: -5px;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 28px;
        transition-duration: 0.4s;
        transform: scale(0);
    }

    label img {
        height: 80px;
        width: 80px;
        transition-duration: 0.2s;
        transform-origin: 50% 50%;
    }

    :checked+label {
        border-color: #0073aa;
    }

    :checked+label::before {
        content: "✓";
        background-color: #0073aa;
        transform: scale(1);
        z-index: 9;
    }

    :checked+label img {
        transform: scale(0.9);
        box-shadow: 0 0 5px #333;
        z-index: -1;
    }

    .options {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1;
    }
}

.media-image-container {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.image-card {
    .file-title {
        float: left;
        height: 80px;
        padding: 5px;
        width: 95%;
        max-width: 200px;
        padding: 0 15px;

        .file-title-name {
            display: block;
            font-weight: 700;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: all .3s ease;
            white-space: nowrap;
        }

        .file-title-meta {
            color: #929292;
            height: 20px;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                font-size: 12px;
                margin: 0 5px 0 0;
            }
        }
    }
}

.ant-image {
    float: left !important;
    cursor: pointer !important;
    margin: 3px !important;
}

.upload-container {
    cursor: pointer;
    text-align: center;
    padding: 20px;
    margin: 20px;
    border: 2px dashed #f0f0f0 !important;

    svg {
        width: 100px;
        height: 40px;
    }
}

.upload-drag {
    border: 2px dashed #096dd9 !important;
}


.media-library {
    height: 75vh;
    overflow: auto;
    display: flex;
    margin: 20px;
    border: 2px dashed #f0f0f0 !important;
    flex-wrap: wrap;
}

.upload-image-preview {
    margin: 20px;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}