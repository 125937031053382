@mixin hr_title($content) {
  color: #797979;
  border: 0;
  height: 2px;
  background-color: #797979;
  text-align: center;
  margin-top: 50px;
  padding: 0;
  &:after {
    background: #f8f8f8;
    content: $content;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    padding: 0 7px;
    position: relative;
    top: -0.7em;
  }
}

.cms_slider {
  @include hr_title("Slider");
  margin-bottom: 20px;
}

.slider-caption {
  font-size: 30px;
  font-weight: 600;
  font-style: italic;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 10rem;
  left: 10rem;
}

.slider-selector {
  position: relative;
  .slider-edit {
    position: absolute;
    top: 0;
    right: 0;
    background: $primary;
    padding: 10px 10px 20px 20px;
    color: #fff;
    border-radius: 0 0 0 100%;
    z-index: 1;
    cursor: pointer;
    &:hover {
      background: #0a68c7;
    }
  }
}

.cms-slider-block-item {
  width: 93px;
  height: 93px;
  border: 1px solid #dbf2ff;
  padding: 15px 0 0
}
.cms-item-slider {
  width: 21px;
  height: 21px;
  border-radius: 2px;
  font-size: 12px;
}

.cms-edit-image {
  width: 30%;
}

.cms-image-button {
  padding: 5px 15px;
  background: $primary;
  border-radius: 0 12px 12px 0;
  color: #fff;
  cursor: pointer;
  &:hover {
    background: #0a68c7;
  }
}

.cms-create-image {
  margin-top: 20px;
  width: 100%;
  margin-inline: auto;
  height: 12rem;
  border: 1px dashed $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: $primary;
}

.cms-grid-item {
  max-width: 20%;
  & > .item-grid {
    border: 1px solid #dcdcdc;
    height: 10rem;
    width: 100%;
    background: $primary;
  }
}
