@import "../../@core/scss/base/bootstrap-extended/variables";
@import "./views/pagination-component.scss";
@import "./views/gallery-image.scss";
@import "./views/live-stream";
@import "./views/e-commerce";
@import "./views/company";
@import "./views/users";
@import "./views/video";
@import "./views/job";
@import "./views/cms";
@import "./views/filemanager";

.basic-font-family {
  font-family: $font-family-monospace;
}

.ant-pagination-item-active {
  background: $primary !important;
  color: #fff !important;
  border-color: $primary !important;
}

.ant-pagination-item {
  // border: 1px solid $primary !important;
  border: none !important;
  color: $primary;
}

.table-category {
  .ant-table-tbody>tr>td {
    padding: 10px 16px !important;
  }
}

// .ant-select-selector,
// .ant-input-lg {
//   border-radius: 0.375rem !important;
//   min-height: 38.27px !important;
//   padding: 4px 11px 4px 11px !important;
// }

.ant-input-lg {
  font-size: 14px;
}

label.ant-form-item-required {
  position: relative;
}

.ant-form-item {
  margin-bottom: 10px;
}

.ant-form-item-explain-error {
  color: rgb(255, 0, 0);
  font-size: 12px;
  font-style: italic;
}

.ant-form-item-label {
  padding: 0;
}

// [dir] .ant-radio-checked .ant-radio-inner {
//   border-color: $primary !important;
// }

// [dir] .ant-radio-inner::after {
//   background-color: $primary !important;
// }
.ant-select-tree-switcher, .ant-tree-switcher{
  display: flex;
  justify-content: center;
  align-items: center;
}
// .ant-select-single{
//   height: 38px;
// }
:where(.css-dev-only-do-not-override-12jzuas).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: $primary !important;
  background-color: $primary !important;
}

.tox .tox-statusbar a {
  display: none;
}

.tox .tox-statusbar__path {
  display: none !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 40px !important;
  width: 20px !important;

  &:hover {
    color: $primary !important;
  }
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 14px !important;
  width: 14px !important;

  &:hover {
    color: $primary !important;
  }
}

[dir] .ant-tabs-ink-bar {
  background: $primary !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary !important;
}

// [dir] .ant-btn-primary {
//   border-color: $primary !important;
//   background: $primary !important;
// }

//* class config
.fw-extrabold {
  font-weight: 700;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.hover-effect:hover {
  color: #ff8800;
}

.hover-option-user {
  padding-block: 5px;

  &:hover {
    background: #fff4e4;
  }
}

//* toaster
[dir=ltr] .Toastify__progress-bar {
  transform-origin: right !important;
}

.hover-content {
  background-color: transparent;
  transition: all 1s;
  -webkit-transition: all 1s;

  & :hover {
    background-color: #f9f9f9;
  }
}

.flex-full {
  flex: 1;
}


.rounded-btn {
  border-radius: 50%;
  padding: 5px;
}
.ml5{
  margin-left: 5px;
}

// post crawl data
.post_crawl .content {
  margin: 0 !important;
}

.btn-action {
  padding-block: 4px;
  padding-inline: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.text-three-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Số dòng muốn hiển thị */
  -webkit-box-orient: vertical;
}

.text-five-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Số dòng muốn hiển thị */
  -webkit-box-orient: vertical;
}


.text-four-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Số dòng muốn hiển thị */
  -webkit-box-orient: vertical;
}


.text-two-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Số dòng muốn hiển thị */
  -webkit-box-orient: vertical;
}

.text-one-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Số dòng muốn hiển thị */
  -webkit-box-orient: vertical;
}
