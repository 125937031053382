.stream-title {
  font-size: 18px;
  font-weight: 700;
  color: #747474;
}
.card-hover:hover {
  transition: all 0.3s ease-in-out, margin-bottom 0s, color 0s, border-color 0s;
  -webkit-transition: all 0.3s ease-in-out, margin-bottom 0s, color 0s,
    border-color 0s;
}
.background-livestream {
  background-size: cover;
  width: 100%;
  height: 65vh;
  position: relative;
  border-radius: 9px;
}

.button-edit-image {
  width: 50px;
  height: 50px;
  border-radius: 0 9px 0 50px;
  background: #0779e96c;
  padding: 5px;
}
.post-media-card-image {
  border: 1px solid #cccccc;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
}

.post-media-card-image-active {
  border: 4px solid #0779e9;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
}
.live-highlight {
  // position: absolute;
  padding: 10px;
  background-color: #0779e9;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
}
.comment-stream-content {
  padding: 10px 25px;
  background: rgb(255, 242, 226);
  border-radius: 0 30px 30px 30px;
}

