.image-pending {
    opacity: 0.3;
    width: 75%;
    height: 170px;
    background-size: cover;
    margin: auto;
}
.image-unpending {
    width: 75%;
    height: 170px;
    background-size: cover;
    margin: auto;
}