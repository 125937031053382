.list-company-logo {
    width: 50px;
    height: 50px;
    border-radius: 9px;
    overflow: hidden;
}
.detail-company-logo {
    width: 100px;
    height: 100px;
    border-radius: 9px;
    overflow: hidden;
}
.detail-company-name {
    font-size: 19px;
    font-weight: 800;
}
.tab-item {
    padding: 10px 20px;
    border-radius: 9px 9px 0 0;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
}
.tab-item-active {
    background-color: #0779e9;
    color: #fff;
}