.job-avatar {
  border: 1px solid #000;
  width: 20%;
  margin-inline: auto;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  border: 1px dashed $primary;
  cursor: pointer;
}

.job-avatar-edit {
  border: 1px solid #000;
  width: 70%;
  margin-inline: auto;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  border: 1px dashed $primary;
  cursor: pointer;
}

.job-item-icon {
  width: 45px;
  height: 45px;
  background: #ebebeb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}