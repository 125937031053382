.product-image-upload {
  border: 2px dashed #dcdcdc;
  border-radius: 10px;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  &:hover {
    border: 2px dashed #0779e9;
    color: #0779e9;
  }
}
.image-selected {
  height: 90px;
  width: 120px;
  margin: auto;
  border-radius: 9px;
  overflow: hidden;
}
.btn-add-classify {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 8px;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  border: 1.5px dashed #aaaaaa;
  color: #0779e9;
  &:hover {
    background-color: #0779e923;
  }
}
.card-classify {
  margin-bottom: 15px;
  background: #f6f6f6;
  padding: 20px;
  .btn-clear-classify {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
.thumbnail-category {
  height: 80px;
  width: 80px;
  border-radius: 9px;
  overflow: hidden;
  border: 1px dashed #dcdcdc;
  margin: auto;
  & > img {
    width: 100%;
    height: auto;
  }
}
.thumbnail-product {
  width: 50px;
  height: 50px;
  border-radius: 7px;
  overflow: hidden;
}
.product-icon-status {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.variants-product {
  height: 100px;
  width: 100px;
  border: 1px dashed #b4b4b4;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b4b4b4;
  border-radius: 9px;
  cursor: pointer;
  overflow: hidden;
}
.detail-title-product {
  font-size: 18px;
  font-weight: 600;
}
.detail-card-price {
  padding: 20px;
  width: 100%;
  background: #ffebe369;
  margin-top: 10px;
  text-align: center;
  font-size: 20px;
  color: #0779e9;
  font-weight: 600;
}
.detail-btn-variants {
  border: 1px solid rgb(190, 190, 190);
  border-radius: 6px;
  padding: 5px 10px;
  color: #000;
  cursor: pointer;
}
.detail-btn-variants-active{
    border: 2px solid $primary;
    color: $primary;
}
.box-img-update-variants {
  border-radius: 9px;
  overflow: hidden;
}
.oder-box-logo {
  width: 70%;
  overflow: hidden;
}
.order-detail-status {
  border: 1px solid #DCDCDC;
  padding: 5px 15px;
  border-radius: 50px;
}
.order-detail-product-box-img {
  width: 80px;
  height: 80px;
  border-radius: 9px;
  overflow: hidden;
}