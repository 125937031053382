
.video-scope-item {
  padding: 5px 4px;
  font-weight: 500;
  color: #0779e9;
}

.video-view-container {
  width: 100%;
  height: 65vh;
  background: #000;
  // border-radius: 9px;
  overflow: hidden;
}

.video-detail-title {
  font-weight: 600;
  font-size: 18px;
}

.video-detail-func-button {
  background: #e6e6e6;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  border-radius: 50px;
  cursor: pointer;
}
.video-detail-desc {
  background: hwb(0deg 95% 5%);
  border-radius: 9px;
  min-height: 10vh;
}

.video-detail-tab-item {
  padding: 5px 15px;
  border: 1px solid #0779e9;
  border-radius: 10px;
  color: #fff;
  background: #0779e9;
}

.video-thumbnail-detail {
  width: 100%;
  height: 7em;
  background: #000;
  border-radius: 9px;
  overflow: hidden;

}

.video-detail-more-title {
  width: 100%;
  font-weight: 600;
  font-size: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}





